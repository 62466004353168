import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withPage } from "../PageContainer";
import * as Widget from "../Components/Widget";
import UnitDetailDialog from "../Components/UnitDetailDialog";
import { unitData } from "../Utils/UnitData";

class UnitIntroPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDetailDialog: false,
      selectedUnit: null
    };
  }

  render() {
    let { openDetailDialog, selectedUnit } = this.state;
    return (
      <Wrapper>
        <img className="banner" src={"./images/004-2.png"} width="100%" />
        <Container>
          <Widget.Padding />
          <Widget.Row css={{ flex: 2.5 }}>
            <Widget.Col css={{ position: "relative", flex: 3 }}>
              <img
                style={{ marginLeft: 58 }}
                src={"./images/011.png"}
                width="50px"
                height="50px"
              />
              <Widget.BtnsWrapper>
                <Widget.BackBtn to="/about">
                  <img
                    className="back-btn"
                    src={"./images/007.png"}
                    width="115px"
                  />
                  <p>回前頁</p>
                </Widget.BackBtn>
                <Widget.HomeBtn to="/">
                  <img
                    className="back-btn"
                    src={"./images/008.png"}
                    width="115px"
                  />
                  <p>回首頁</p>
                </Widget.HomeBtn>
              </Widget.BtnsWrapper>
            </Widget.Col>

            <Widget.Col css={{ flex: 6, paddingRight: 40 }}>
              {unitData.map((unit, idx) => (
                <UnitItem
                  idx={idx}
                  onClick={() =>
                    this.setState({
                      selectedUnit: unit,
                      openDetailDialog: true
                    })
                  }
                >
                  <img src={`./images/units${unit.icon}`} height="80px" />
                  <p>{unit.name}</p>
                  <div className="children">
                    {unit.children.length > 0 &&
                      unit.children.map((u, _idx) => (
                        <img
                          src={`./images/units${u.icon}`}
                          height="80px"
                          key={_idx}
                        />
                      ))}
                  </div>
                </UnitItem>
              ))}
            </Widget.Col>
          </Widget.Row>
          <Widget.BottomDeco />
        </Container>
        {openDetailDialog && (
          <UnitDetailDialog
            unit={selectedUnit}
            closeDialog={() => this.setState({ openDetailDialog: false })}
          />
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > .banner {
    background-color: lightgray;
    border-radius: 30px 30px 0px 0px;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 84%
  );
`;

const UnitItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 30px;
  border-radius: 40px;
  background-color: ${props => (props.idx % 2 == 0 ? "#efefef" : "#fff")};

  & > p {
    flex: 1;
    margin-left: 15px;
    color: #3e3a39;
    /* text-align: center; */
    font-size: 28px;
    letter-spacing: 1pt;
  }

  & > .children {
    flex: 1;
  }
`;

export default withPage(UnitIntroPage);
