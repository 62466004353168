import React from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

class UnitDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unit: null
    };
  }
  render() {
    let { closeDialog, unit } = this.props;

    if (this.state.unit) {
      unit = this.state.unit;
    }

    return (
      <Wrapper>
        <Widget.Padding />
        <Widget.Row css={{ alignItems: "center", marginBottom: 70 }}>
          <img
            style={{ marginLeft: 58 }}
            src={`./images/units${unit.icon}`}
            width="300px"
            height="300px"
          />
          <Widget.Padding />
          <Widget.Title css={{ textAlign: "right", flex: 3 }}>
            {unit.name}
          </Widget.Title>
        </Widget.Row>
        <Widget.Row css={{ flex: 2.5 }}>
          <Widget.Col css={{ position: "relative", flex: 1 }}>
            <img
              style={{ marginLeft: 58 }}
              src={"./images/011.png"}
              width="50px"
              height="50px"
            />
            <Widget.BtnsWrapper>
              <CloseDialogBtn
                onClick={
                  this.state.unit
                    ? () => this.setState({ unit: null })
                    : closeDialog
                }
              >
                <img
                  className="back-btn"
                  src={"./images/007.png"}
                  width="115px"
                />
              </CloseDialogBtn>
              <Widget.HomeBtn to="/">
                <img
                  className="back-btn"
                  src={"./images/008.png"}
                  width="115px"
                />
              </Widget.HomeBtn>
            </Widget.BtnsWrapper>
          </Widget.Col>

          <Widget.Col css={{ flex: 5 }}>
            <Widget.Content
              css={{
                fontSize: 20,
                lineHeight: "38px",
                maxHeight: unit.children.length > 0 ? "40vh" : "56vh",
                overflow: "scroll"
              }}
            >
              {unit.content}
            </Widget.Content>
            {unit.children.length > 0 && (
              <Widget.Col css={{ marginTop: 30 }}>
                <Widget.Subtitle css={{ fontSize: 28 }}>
                  <p>{`下轄單位`}</p>
                  <Widget.PaddingLine />
                </Widget.Subtitle>
                <Widget.Row>
                  {unit.children.map(u => (
                    <ChildItem onClick={() => this.setState({ unit: u })}>
                      <img
                        src={`./images/units${u.icon}`}
                        width="150px"
                        height="150px"
                      />
                      <p>{u.name}</p>
                    </ChildItem>
                  ))}
                </Widget.Row>
              </Widget.Col>
            )}
          </Widget.Col>
        </Widget.Row>

        <Widget.BottomDeco />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 73%,
    rgba(255, 255, 255, 0) 84%
  );
`;

const CloseDialogBtn = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

const ChildItem = styled.div`
  width: 150px;
  & > p {
    text-align: center;
    color: #3e3a39;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
  }
`;

export default UnitDetailDialog;
