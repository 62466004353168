const unitData = [
  {
    name: "空軍作戰指揮部",
    icon: "/1-1.png",
    content: `空軍作戰指揮部成立於民國39年4月16日，前身為「國防部防空司令部」，銜稱配合改組多次修訂，民國95年3月1日奉令更銜為「空軍作戰指揮部」迄今，下轄「戰術管制聯隊」、「通信航管資訊聯隊」及「氣象聯隊」等3個作戰勤務支援單位，依「防衛固守、重層嚇阻」戰略指導，遂行臺海空中作戰之規劃與指揮管制，拒敵於千里。

空軍作戰指揮部乃利用日本時代遺留於芳蘭山之彈藥庫坑道，架設防情通信裝備執行作戰指揮，民國47至63年間配合自動化指管系統於蟾蜍山一側擴建1號洞，與原有坑道連接成120洞，民國65至69年間結合南北洞坑道貫通為「福興隧道」，輔以高科技情、監、偵系統，成為現今肩負起國軍防空作戰的指管中樞，依此歷史沿革脈絡，營區名稱於民國106年4月17日更銜為「福興營區」，就是寓意「福澤長空、興國祐民」，象徵指揮部另一階段的進步及傳承。
    `,
    children: [
      {
        name: "通信航管資訊聯隊",
        icon: "/1-2.png",
        children: [],
        content: `通信航管資訊聯隊前身為通信總隊，民國36年1月15日成立於南京光華門防校舊址，政府遷臺後於民國43年更銜為空軍通信聯隊，民國56年2月16日合併航管大隊後成立空軍通信航管聯隊，直屬空軍總司令部，民國80年2月1日奉命改隸作戰司令部，民國93年4月1日聯隊訓練中心與空軍總司令部資訊中心整併，更銜為空軍通信航管資訊聯隊。

通信航管資訊聯隊肩負空軍通信情傳、航行管制及資訊維管三大任務，在國軍歷次戰役中，為保持通信暢通，均能發揮大無畏精神深入危險地帶，誓死達成任務。平時期負責支援各項戰演訓遂行，均能把握通信「迅速、秘密、確實」之要求，航管「安全、迅速、有序」之原則，資訊「網路通達與安全」之目標，戰時則全力支援與滿足作戰部隊通航資任務需求，以發揮最強大之戰力。
        `
      },
      {
        name: "戰術管制聯隊",
        icon: "/1-3.png",
        children: [],
        content: `戰術管制聯隊前身為偵測大隊，民國39年1月1日成立於臺北，隸屬東南軍政長官公署，4月1日改隸國防部防空司令部，民國41年3月1日改編為空軍第1、2防空管制大隊，4月16日更銜為第1、2戰術管制大隊，民國42年9月1日以第1戰術管制大隊為基幹擴編為空軍戰術管制聯隊，直隸空軍作戰司令部，民國45年1月16日復將第2戰術管制大隊納編，奠定現今之組織編裝。

戰術管制聯隊下轄各中心、中隊、分隊、機動分隊單位，主要任務為擔任我早期預警及戰術管制之防空任務，民國68年2月1日由人工作業邁入天網自動化系統，民國83年8月12日因應現代化防空作戰需求，提升為強網系統，民國100年10月24日正式啟用寰網全系統後，完整建構出新世代之戰管部隊，整體聯合情監偵搜索、涵蓋範圍及聯合防空作戰指揮管制能力大幅提升，有效確保國家之空防安全。
        `
      },
      {
        name: "氣象聯隊",
        icon: "/1-4.png",
        children: [],
        content: `氣象聯隊前身為航空測候所，民國18年成立於南京，民國28年1月1日改編為氣象總臺，民國36年1月1日改編為空軍氣象總隊，2月1日成立氣象大隊五處及氣象中隊處駐臺灣。政府遷臺後總隊遷駐淡水，民國43年12月1日擴編成為氣象聯隊，直隸空軍總司令部，民國80年2月1日奉令改隸空軍作戰司令部。

氣象聯隊掌理空軍全般氣象業務之策劃、監督、執行與軍事氣象發展重任，並結合軍事氣象與科學技術，於颱風來臨時運用各式氣象裝備，機動至災防潛勢區蒐集即時天氣資料，旱災時則利用空中人工增雨作業解決民生用水問題，充分展現國軍災防能量。所屬氣象中心、各基地天氣中心暨探空分隊協力臺海周邊戰演訓與防、救災天氣預報、颱風守視、高空氣象觀測、氣象情傳、作戰部隊氣象勤務支援等任務，以達「觀天測地識先機、先知快報護飛安」之目標。
        `
      }
    ]
  },
  {
    name: "空軍教育訓練暨準則發展指揮部",
    icon: "/2-1.png",
    children: [
      {
        name: "空軍航空技術學院",
        icon: "/2-2.png",
        children: [],
        content: `民國20年10月，政府為培植航空機械人員，成立「航空機械學校」籌備委員會，民國25年3月16日建校於江西南昌─此為航校「三一六校慶」之由來。民國27年改制為「空軍機械學校」。民國33年建立「空軍通信電子學校」。航校前身為「空軍機械學校」及「空軍通信電子學校」，在民國85年8月1日，為配合中華民國國軍兵力整建政策，併編成為「空軍航空技術學校」。民國90年，因應國防科技之發展及國家技職教育之變革，經教育部評鑑，改制升格為技術學院，並於民國91年8月1日晉名為「空軍航空技術學院」，使學校成為空軍專門培育二年制技術學院軍官及專科技職士官的技勤幹部學院。

    學校負責培訓航空機械、土木工程、後勤補給、通信電子、飛航管制及大氣預測(報)等專業之二年制技術學院軍官及專科技職士官，作為中華民國的國防科技之發展及國家技職教育之培育院校，亦為中華民國軍事院校中唯一培訓航空專業人才的技勤幹部學院，而歷屆校友更是臺灣戰後航空產業發展主要的生力軍。
        
    近年來學校朝多元學術領域發展，積極參與校際合作、產學研究與發明競賽等。學生在教官師的指導下也開創無數佳績，除了各項全國校際比賽外，連續6年的國際創新發明競賽中，航技學院團隊共締造了12金7銀2銅的傲人成績，並培訓優秀畢業生參加國際技能競賽，於民國104年代表國家參加國際技能競賽，獲得飛機修護類的優勝金牌，民國105年選手也取得國手資格，準備為國爭光。
        
    航空科技日新月異，維保技術與時俱進，在現代戰爭 唯有充分發揮裝備特性及優良技術支援，始可爭取機先，克敵制勝。航院深刻了解科技對國防之重要，秉持完善的教育訓練為建軍的基石，對於飛機後勤保修、通資電、戰航管與各項武器裝備維護，始終能全力以赴，為支援空軍建軍備戰有著諸多功勞與貢獻。「空戰出英雄、地勤一半功」，地勤人員在工作崗位各司其職、善盡本務，是確保每一次戰演訓任務順利完成的重要關鍵。
        `
      }
    ],
    content: `國軍基於敵情威脅及戰略構想，於民國73年5月20日，擇花蓮佳山開工建構地下化停機洞庫，由聯合工程指揮部監造，榮民工程處承包佳山基地營建工程。民國87年7月1日「空軍東部指揮部」裁撤，成立「空軍教育訓練暨準則發展司令部」，民國95年3月1日因應國軍「精進案」組織調整，更銜為「空軍教育訓練暨準則發展指揮部」，下轄空軍航空技術學院、空軍基地訓練指揮部、國軍防空砲兵部隊訓練中心等3個單位。

空軍航空技術學院前身為「空軍機械學校、空軍通信學校」，兩校分於民國25年3月16日及33年1月1日建校，民國85年8月1日兩校併編成立「空軍航空技術學校」，民國91年8月1日改制為「空軍航空技術學院」，負責培訓航空機械、土木工程、技術補給、通信電子、飛航管制及大氣量測等二年制技術學院軍官及專科技職士官，為國軍唯一培訓航空專業人才的技勤幹部學院。
    
空軍基地訓練指揮部民國74年7月7日於臺中清泉崗基地成立。民國80年7月1日由臺中遷至花蓮佳山基地，更銜為「空軍東部指揮部」。民國82年9月1日空軍第八二八聯隊併入，成立「基訓中心」。民國87年7月1日改編成立「空軍基地訓練指揮部」，負責對空軍戰術戰鬥機部隊實施基地整訓及佳山洞庫熟訓任務。
    
國軍防空砲兵部隊訓練中心民國70年12月7日於東港大鵬營區成立。民國80年9月1日更銜三軍防空砲兵基地訓練中心，並於民國92年3月17日搬遷進駐枋山加祿堂營區。民國103年11月1日組織編裝調整移編教準部，更銜為「國軍防空砲兵部隊訓練中心」，負責精進教育訓練、裁判專業測考、靶勤支援能量及新兵專長訓練等4大任務。
    
教準部編成以來，負責培育航空技職人才、精進部隊訓練、專精戰訓測考、前瞻戰術研究、落實準則發展等5大功能，其成果對空軍未來均有深遠影響，責任不僅神聖而且重大。
    
展望未來，秉持「主動負責、積極熱誠」的精神，及以「情境雖緩、而心不緩、則緩而不怠；情境雖急、而心不怠，則急而不亂」自我期許，相信在不斷努力精進下，必能使學校教育、準則運用、人員訓練、戰力整備等脈絡一貫，為空軍奠定可恃之堅實戰力。
    `
  },
  {
    name: "空軍防空暨飛彈指揮部",
    icon: "/3-1.png",
    children: [],
    content: `民國106年9月1日，空軍防空飛彈指揮部移編空軍防空砲兵指揮部，更銜為「空軍防空暨飛彈指揮部」。民國95年1月，配合精進案執行「和平專案」，警衛兵力移編憲兵司令部及執行「長泰專案」，將飛彈司令部所屬防空飛彈部隊移編空軍，併編成立「空軍防空砲兵司令部」，下轄防砲指揮部、飛彈群、三軍防空砲兵訓練中心、防空砲兵營、防空飛彈營及儀隊連；同年3月，正式更銜為「空軍防空砲兵指揮部」，並於8月16日指揮部自桃園縣八德市更寮腳忠勇營區搬遷至臺南縣仁德鄉長安營區。

民國96年7月組織修訂，原防砲指揮部及飛彈群改編為防空砲兵旅（第九五一至九五四旅），另轄有國軍防空砲兵訓練中心、防空砲兵營及儀隊連；民國100年7月1日執行「精粹案」第一階段，裁撤防砲第二○八及第二一三營。
    
民國101年2月16日執行「泰安專案」，防空飛彈部隊改隸國防部參謀本部防空飛彈指揮部，防空第九五一及第九五四旅移編飛彈指揮部；「防空部」銜稱續用，防空第九五二及第九五三旅更銜為防砲第一四一群及第一四二群，另轄有防砲作戰管制中心、國軍防空砲兵訓練中心、綜合保修廠、三五快砲營、車載劍一飛彈營、四○砲營及儀隊連；同年7月防砲第二○七營及第二一六營裁撤。
    
民國102年4月1日儀隊連移編司令部勤務中隊。民國103年11月1日國軍防空砲兵訓練中心移編教準部，更銜為「國軍防空砲兵部隊訓練中心」。
    
為因應日新月異的現代科技戰爭，有效遂行現代化防空作戰，確保空軍基地、雷達站之防空安全，防空部正陸續規劃採購「先進防空飛彈系統」及「低空防空快砲」，期使防砲火力具有長短相輔、多層涵蓋及區域目標火力分配能力，提升整體防空戰力，並持續執行「永固專案」，重新規劃戰鬥地境線、組織調整及整合等手段，期達指揮權責統一，調度支援迅速等效益，以符合未來整體防空作戰需求，達成維護臺海空防安全之神聖使命。
    
    
    
民國106年9月1日，空軍防空飛彈指揮部移編空軍防空砲兵指揮部，更銜為「空軍防空暨飛彈指揮部」。殷憂啟聖，多難興邦，飛彈部隊創建於風雨飄搖的年代。民國47年，爆發金門八二三砲戰，為防止中共戰機對我威脅與挑釁，美國緊急調派位於阿拉斯加的勝利女神力士飛彈營到臺灣協防。民國48年陸軍正式接收美軍力士飛彈裝備，我國第一支肩負空防重任的飛彈部隊至此正式成軍，中華民國正式進入飛彈時代，也奠定飛彈部隊在國軍建軍史上不可抹滅的歷史定位。民國49年陸軍飛彈第二營編成，專責鷹式飛彈裝備；其和力士飛彈都是我國第一代防空武器的主力，兩者相互搭配，再配合防空快砲，構成強大空衛屏障，使敵機不敢越雷池一步。民國50年飛彈第一營首次力士飛彈實彈射擊，代名「神箭演習」。
 民國51年飛彈第一營改編為飛彈第六六一營、第二營改編為六六二營。民國53年第六六二營完成整訓後，全數接收裝備，開始擔任戰備任務，同年美軍正式將鷹式飛彈移交給陸軍飛彈第六六二營，這是繼勝利女神飛彈後，美軍軍援我國的第二種防空飛彈。民國53年3月，「神箭五號演習」最令人矚目的是鷹式飛彈首次試射。演習傑出的射擊成果不僅讓第六六二營獲得太平洋區鷹式飛彈射擊最優單位之殊榮，更印證飛彈部隊主宰戰場的紮實戰訓成果。
 民國85年向美國採購愛國者飛彈系統，編成愛國者飛彈營，執行反制飛彈任務。民國90年愛國者飛彈首次在「神箭42號」操演公開試射，成功驗證飛彈反彈道的作戰能力。民國95年依「精進案」第二階段組織調整，編成「飛彈指揮部」。民國101年依「精粹案」指導，整併中、高空防空飛彈部隊，編成「參謀本部防空飛彈指揮部」，民國106年3月1日依國防部政策指導更銜為「空軍防空飛彈指揮部」移編空軍，持續守護臺灣防空任務。
    
    `
  },
  {
    name: "空軍保修指揮部",
    icon: "/4-1.png",
    children: [
      {
        name: "第一後勤指揮部",
        icon: "/4-2.png",
        children: [],
        content: `第一後勤指輝部於民國43年11月1日成立屏東，由當時之空軍飛機修理總廠、空軍補給總庫、宜蘭第一飛機製作廠、空軍通信修理廠及七一三、九一七汽車中隊等單位改組成立為「空軍第一供應區部」，隸屬「空軍供應司令部」。民國66年7月1日奉令更銜為「空軍第一後勤指揮部」，隸屬「空軍後勤司令部」。

民國94年12月31日「空軍後勤司令部」編併聯合後勤司令部，一指部於民國95年1月1日奉令隸屬「空軍總司令部」。民國95年2月27日配合國防二法，奉令隸屬「空軍司令部」，復於民國97年2月1日隸屬新成立之「空軍保修指揮部」。一指部現行主要任務係執行空軍各型戰(飛)機、空用儀表、空用通信電子裝備、軍械火控系統、飛彈、地面雷達等修護、試驗裝備校正修理、零附件配製，分屬4大維修工廠（飛機另件修理廠、軍械電子廠、試驗裝備廠、陸用電子廠）。
        
回顧一指部創始自今，篳路藍縷，經歷任指揮官卓越領導與全體同仁胼手胝足，不辭勞苦，曾先後完成本軍T-33、F-104、F-86、F-100、F-5E/F、AT-3等各型機工廠階段修護及機體結構修理、載重平衡、噴漆等任務及AIM-9B/E、AIM/9N、AIM/9P3、AIM/9P4飛彈導引組、協修海、陸軍AN/DAW-1飛彈導引組及海軍陸戰隊AN-DAW-2A飛彈導引組能量、35快砲武器系統、麻雀飛彈等修護與能量籌建，空軍各型機儀電＼導航＼雷達系統、F-5雙組陀螺等儀表、 F-5E/F加裝RWR/CDS作業與修護及虎瞰機專案；在陸用雷達方面具有FPS/100、TPS-43F、GE-592、HARD、KY-1000A、AN/GRN-19A、TPS-117、TPS-75V及AN/MPN-14K（SS）等型雷達修護部署架設；運作期間因試驗裝備修理及校驗技術精良，獲美軍太空導引中心及度量衡中心評定，合乎國際水準，於民國50年頒發遠東區唯一之二A等級量測證書及獲經濟部核准實驗室儀具、電量、長度校正等認證。民國88年4月27日通過國家標準檢驗局ISO-9001驗證，頒發合格證書，更為國軍首創，繼於民國92年6月10日獲經濟部標檢局評鑑合格，獲頒ISO14001環境管理系統認證，復於民國94年12月通過標檢局OHSAS18001國際職業安全衛生政策評鑑及民國97年12月29日TOSAH臺灣職業安全衛生管理系統認證，以完全建構成為優質的後勤指揮部。
        
民國94年因應「二指部國有民營案」奉令執行合約管理任務，除強化履約實務面管理作為外，並建立本軍系統化、制度化及資訊化之履約管理體系，俾有效提升修護品質，確維軍機妥善。
        
民國102年1月17日，屏東大聖營區水湳遷建工程竣工，計新建五棟雙聯修護棚廠(101至110)、地裝工廠、有機溶劑庫房、廢水處理廠等主要設施，其水湳機場搬遷專案第二階段搬遷，於11月11日正式啟動屏東大聖營區修護能量轉移復原作業。
        
鑑於科技與裝備發展日新月異，盱衡任務日趨加重，唯有加強技術訓練，培植新才，方能達成空軍未來任務發展，當前一指部修護能量，除已具備建軍備戰要求外，並能支援友軍及國內公、民營單位；期許全體同仁更加惕勵，勤儉建軍之準繩，不斷研究發展，擴張自修自製能量，完成上級賦予之使命。
        `
      },
      {
        name: "第三後勤指揮部",
        icon: "/4-3.png",
        children: [],
        content: `空軍第三後勤指揮部，前身為廣東韶關飛機製造廠，於民國13年進行規劃，民國24年正式成立，民國25年更銜為「韶關飛機製造廠」。民國27年因中日戰爭奉令由香港經越南海防歷時九個月遷廠至雲南昆明後，再次更銜為「空軍第一飛機製造廠」。民國38年因國共內戰，奉令自昆明遷往臺灣宜蘭。民國43年11月16日遷廠至岡山現址成立空軍第一供應處，隸屬屏東空軍第一供應區部，負責L-5、L-17、PT-17、PT-19、AT-6、AT-11、F-6等螺旋槳飛機工廠階段修護任務，民國46年改隸直屬空軍供應司令部，民國53年完成J-33及J-47兩型噴射發動機工廠階段翻修能量後，從此邁入噴射發動機翻修時代，並逐年完成J79-11/19、J-57、-85-5H/-13-17/-21及T53-L-13B/701A等各型噴射及渦輪軸發動機翻修能量。

民國66年更銜為「空軍第一後勤支援處」。民國86年7月1日配合「精實案」政策，與空軍第三後勤支援處併編成立「空軍第三後勤指揮部」，分駐於岡山及花蓮南埔營區，以各類型航空器發動機修護、空、陸用武器裝備五金零附件配置、航空燃油、滑油化驗、液壓油汙試驗與施工溶液分析為主要任務。
        
三指部負責國軍各式航空器發動機修護任務，體認「國防自主」的重要性，除秉持後勤先鋒的精神，嚴密控管各項修護能量，目前已具備國軍現役13型20種發動機、模組、零附件(組)件翻修能量，更積極籌建主力戰機計畫性修護能量，致力於如期如質建構翻修能量，以「面對問題、解決問題」永不放棄的堅持信念，贏得「空軍守護神」的美譽外，更著眼高科技國防工業，針對武器裝備的後勤維保作業及自主支援三軍部隊之能量為重要目標，以證明國軍後勤維保部隊具有充足技術及能量，支援三軍部隊需求及提升戰力，更可確保不需過度依賴國外支援，可適時遂行各項戰演訓任務，持續朝向國防自主的目標邁進。
        `
      },
      {
        name: "料配件總庫",
        icon: "/4-4.png",
        children: [],
        content: `聯勤接儲總庫於民國43年11月1日由空軍補給總庫暨台南汽車修理廠合併成立空軍第二供應處，隸屬第一應區部。民國46年2月16日改隸為空軍供應司令部直屬單位。民國66年7月1日因應任務需要將本處原名空軍第二供應處更名為空軍第二後勤指揮部支援處。為配合國軍精實案及二代機任務需求與減少指揮管理層級，民國85年9月1日合併空軍油料大隊，基隆運輸站、高雄運輸站，工程隊暨三個靶場勤務分隊成立空軍第四後勤指揮部。民國89年6月1日為執行精實案「專業指部簡併」空軍第二後勤指揮部與空軍第四後勤指揮部合併組織為空軍第二後勤指揮部。後續配合國軍精進案第2階段後勤組織調整，奉國防部民國94年10月12日略畫字0940001414號令空軍第二後勤指揮部「倉儲中心」、「接檢中心」於民國94年11月1日移編聯勤成立接儲總庫。民國100年1月1日聯勤接儲總庫移編至空軍保修指揮部成立空軍料配件總庫。`
      }
    ],
    content: `保修指揮部前身為「空軍供應司令部」，民國35年8月1日成立於上海，直屬空軍總司令部，下轄空軍補給總庫與各飛機修理廠等26個單位，負責掌理空軍後勤供應任務。民國38年由上海播遷來臺後駐防臺南。民國42年1月，原屬供應司令部之各供應大隊移撥併編各作戰聯隊，並改編為各作戰聯隊下之修謢補給大隊及基地勤務大隊。民國58年遷入臺南長安營區。民國66年7月1日供應司令部更銜為後勤司令部。民國87年7月1日，空軍總司令部後勤署併入後令部。民國95年1月1日，因應「精進案」空軍後勤司令部裁撤。民國97年因「精實案」及「精進案」遂行，配合精簡裁撤，後因「精進案」第2階段執行成效檢討政策指導，為有效建構軍種專用後勤督管機制，於民國97年2月1日成立保指部，以期達「精準後勤」目標。

保指部隸屬司令部，為部外一級單位，編設指揮官室、綜合作業組、修護管理組、補給管理組、整體後勤組、軍械電子組、商維管理組、採購管理組、後勤資訊組、法務組、主計組及職業安全衛生組，共計12組。民國103年因應行政院舊址開發，於同年2月份搬遷至忠勇營區，下轄後勤第一指揮部、後勤第三指揮部及料配件總庫。
    
保指部成立之目的，在秉持司令部之後勤政策指導，縱向指揮第一、三後勤指揮部等修護、補給後勤體系，藉由審慎規劃及洞察後勤任務推展窒礙，提供協助指導與解決方案；同時橫向協調支援部隊，以交互支援模式，確保各項後勤修、補任務執行順遂及賡續秉持「空戰出英雄，地勤竟全功」之精神，有效支援作戰。
    `
  },
  {
    name: "空軍軍官學校",
    icon: "/5-1.png",
    children: [
      {
        name: "飛行訓練指揮部",
        icon: "/5-2.png",
        children: [],
        content: `民國18年於南京創立航空班，是為本部飛行訓練之始。21年隨母校遷至杭州筧橋，抗戰期間輾轉至昆明，戰事膠著之際，初級班曾遷至印度臘河遠赴美國代訓，34年12月抗戰勝利奉令籌備遷返抗州筧橋，35年6月15日舉行筧橋復校典禮。38年遷至現址岡山。迄49年止，飛行訓練曾先後由空軍官校教育處或教育長負責督導，49年8月16日正式成立專責戰位－飛行訓練處，58年3月1日將飛行訓練處更名為飛行訓練中心，69年8月1日改制為飛行訓練指揮部。`
      }
    ],
    content: `空軍軍官學校之成立，源自 國父孫中山先生有感於航空對國防的重要，東渡日本洽借陸軍航空訓練所，籌組「中華革命黨」航空學校，並手書「志在冲天」、「航空救國」墨寶，號召有志青年加入航空救國行列，奠定了創校基礎。

民國17年，先總統 蔣公秉承 國父遺志，在中央陸軍官校成立航空隊，設址南京。次年改為航空班，遴選中央軍校畢業生70人，編成甲、乙兩班，釐定教育計畫與課程，並以三個月為一學期，全學程為六學期，畢業後接受飛行訓練，是為今日空軍軍官學校教育體制之雛形。
    
民國21年9月１日，更銜為「中央航空學校」，設址筧橋，並於學校教育條例第一章總則中明白揭示「培養航空人才，俾學員得受航空必要之學術，以期為黨國效用」為學校教育目的，故廣招全國青年，增加普通高中畢業生考選飛行生30名，擴編行政人員，健全行政系統，奠定了學校日後發展的宏規。
    
建校發展時期，抗戰爆發，敵機一日數至，欲毀我正萌芽之空中武力而後已，學校亦隨戰事經歷了4次變遷；分別在民國26年遷至雲南昆明，民國34年遷至印度臘河，勝利後次年於筧橋復校。期間，官校師生忍辱負重，一面與敵作戰，一面積極擴充，先後培植大量飛行人員投入戰場，更在筧橋上空擊落敵機多架，締造了「八一四」空戰大捷的光榮史實。
    
民國38年，中共全面叛亂，學校奉令遷校臺灣岡山，全校人員、飛機器材及圖書，分別採飛機、軍艦運送抵臺；此時期臺海情勢緊張，學校師生非僅在殘垣斷瓦廢墟中，以堅苦卓絕之意志，繼續展開各期學生的學、術科教育，同時訓練飛行學員，更肩負空中與地面作戰任務，這種教育與作戰兼具的型態，本校仍維持迄今，迥異於國內其他軍事院校。
    
民國49年，學校為健全教育體制，仿效美空軍軍官學校制度，採通才教育，不分系，於完成四年制大學教育後再進入飛行訓練，俾建立完整之教育體制，以收「體用合一」之效。民國76年，為配合空軍戰鬥機兵力發展，及擴充飛行人員訓練能量政策，即著手規劃教育體制、組織編制等革新方案，重點置於學制及課程方面，更於民國89年，將原採不分系之通才教育，精進為航太、航機、航電及航管四系之「通、專並重」之分系教育，達到培育空軍所需各類專長軍官之目的。
    
回首10年，空軍軍官學校歷經第十一修護大隊裁編、航空教育展示館揭幕，為符合建軍所需，持續培育優質之空軍飛行員，除在校務方面精進各項課程設計、強化航空專業知識，更積極推廣通識教育與提升外語能力，以落實全人教育，拓展國際化之視野。
    
回顧空軍軍官學校創校歷史，歷經剿共、抗戰、戡亂各時期，期間幾番遷徙流離，猶能艱苦卓絕，弦歌不輟。今後學校仍將秉持以往建軍備戰精神，賡續為培育現代化飛行員而努力，以發揚筧橋精神，為確保中華民國領空而努力奮鬥。
    `
  },
  {
    name: "空軍航空科技研究發展中心",
    icon: "/6-1.png",
    children: [],
    content: `航發中心成立背景可追溯至民國35年，為能自立產業研發，擺脫外援依賴，於南京成立「空軍航空工業局」，政府遷臺後，民國43年改制「空軍技術局」，民國58年擴編「空軍航空工業發展中心」，72年配合「安翔計畫」改隸中科院，設計研發，PL-1、TC-H-1、XC-2、XA-3與AT-3等多型飛機，民國76年，為著手我國第一架噴射戰鬥機研製，於臺中成立「安翔計畫管理室」，隨IDF交裝，民國81年改編「武器系統獲得管理室」，下轄「測試評估隊」。
歷經多次組織改革，原武獲室能量併入企劃處，測評隊改編為「測評戰研中心」，於民國85年改組國有民營事業「漢翔航空公司」，完成階段性使命；如今，為因應國機國造政策指導，整合前航發中心與武獲室核心任務，正式成立「航空科技研究發展中心」。
    
航發中心核心任務為，依據空軍作戰需求，執行武獲流程中，前端「概念設計」和後續「專案管理」、「測試評估」等任務。當航發中心完成「概念設計」規劃後，交由中科院整合國內航太產能，推動研發生產工作。
     
現階段航發中心將先以督管「國機國造」「高教機」為基礎，置重點於人才獲得與培育，累積科技研發專業能量，以成為空軍「科技建軍」先驅和推動者為目標，努力精進，以不負國人期望。
    `
  },
  {
    name: "空軍第一戰術戰鬥機聯隊",
    icon: "/7-1.png",
    children: [
      {
        name: "第一戰鬥機作戰隊",
        icon: "/7-2.png",
        children: [],
        content: `前身第一中隊於民國23年3月2日成立於杭州，由當時航校轟炸班擴編而成，為我空軍第一支轟炸部隊，曾參加剿匪、抗戰及勘亂諸戰役，戰績輝煌。自39年奉令至臺中修改編裝，復於42年進駐臺南基地。於抗日戰爭及勦匪時期，本中隊使用SB輕型轟炸機及B-25轟炸機，擔負轟炸任務。42年4月16日開始換裝F-84G噴射機，自此，本中隊正式進入噴射機時代，擔負防空作戰及捍衛領空的神聖使命。47年9月1日接收F-86型噴射戰鬥機，55年12月9日再換裝F-5A型噴射戰鬥機。而為加強防空戰力，64年6月2日換裝F-5E型中正號噴射戰鬥機；為因應國際新一代戰機空防時代，86年3月8日換裝經國號戰機，於87年6月16日完成戰備，正式擔負各項戰備任務。
        為使成熟飛行人員長留久用於第一線飛行部隊，以充實飛行部隊戰力，91年12月1日奉令改編為第一戰術戰鬥機作戰隊，賡續執行臺海防衛作戰，94年1月1日奉令擔負經國號戰機換裝訓練、戰術專精及空對面擴訓等任務，成效卓著。95年，依行政院國防自主及扶植國內航空工業發展為優先之指導，執行經國號中壽期性能提升之「翔展計畫」，於101年7月31日完成IDF-C/D型機換裝訓練，賡續執行臺海防衛作戰。
        第一作戰隊成立至今，全體官兵本著隊訓「主動」、「負責」、「積極」、「熱忱」之精神，於執行各作戰演習任務或一般飛行訓練時，均能稟承上級命令，發揮「忠勇」軍風，圓滿達成各項任務。
        `
      },
      {
        name: "第三戰鬥機作戰隊",
        icon: "/7-3.png",
        children: [],
        content: `民國32年11月1日成立於四川省溫江機場，隸屬一大隊，旋即赴印度克拉蚩機場接受B-25換裝訓練，完訓後程曾參與緬北反攻戰，33年返防執行，貴柳、湘西、鄂西、中原各戰役，戰果輝煌，抗戰勝利進駐漢口，賡續參加東北、華北、西北、華中、華南、海南島、舟山群島等剿匪戰役，戰績卓越。
        36年於漢口換裝「蚊式」輕型轟炸機，37年12月遷臺中，42年移駐臺南，44年元月換裝F-84G噴射戰鬥轟炸機，成為我國最先完成戰鬥轟炸機部隊，爾後參加臺海戰役，戰果豐碩，47年換裝F-86F噴射戰鬥機，56年5月接受玉山二號F-5A/B機換裝訓練，複於64年6月至65年1月完成嵩山三號F-5E/F機「中正號」換裝訓練，88年元月完成國人自製鷹揚戰機「經國號」合歡山三號換裝任務，擔負起捍衛領空重責大任，於91年12月1日奉令修改編裝為第三戰術戰鬥機作戰隊，於100年11月完成IDF MLU型機轉換訓，賡續執行臺海防衛作戰，本隊在歷任隊長英明卓越之領導下，殊榮磊磊，譽滿全軍，全體官兵皆能本著「勤勞」、「嚴格」、「踏實」、「創新」之原則，激勵「積極進取」、「奮發圖強」、「團結合作」、「勇往直前」之精神，於執行作戰演習訓練中，均秉持專精熟練、敢戰樂戰之要求，充分發揮親愛精誠、忠勇奮戰之團隊精神，深信在每位同仁不斷努力之下，必能將戰力發揮至極致。
        `
      },
      {
        name: "第九戰鬥機作戰隊",
        icon: "/7-4.png",
        children: [],
        content: `民國19年1月1日於南京成立，部隊番號航空第四隊，編配可塞機7架，為我空軍最早之作戰部隊。21年12月併航空第六隊於南昌成立偵察隊，增配摩期機兩架。27年1月改編為第四輕轟炸中隊。34年3月接收美製FB-25中型轟炸機，37年9月換裝加拿大製FB-26蚊式機。42年6月接收美製F84-G雷霆式噴射機，成為我空軍最先換裝之噴射機部隊，並改編為第九戰鬥轟炸中隊。44年4月14日，本隊飛行員劉景泉及葉李榮合力擊落中共MGI-15一架，創下本軍雷霆機第一次擊落紀錄。47年9月換裝F-86F全天候軍刀機。56年換裝F-5A/B自由鬥士戰鬥機。63年2月換裝F-5E/F虎二型戰鬥機後，改編第九戰術戰鬥機中隊。
        88年1月起，依據「合歡山三號」計劃，選派飛行員換裝經國號戰機，同期接收第一架經國號戰機。迄89年1月中旬計完成21架經國號戰機接收任務。飛行員換裝後，復經戰備持續訓練及三階段精練訓練，於89年7月14日配合聯隊成軍，完成中隊編制並擔負戰備，並於91年12月1日奉令修改編裝，改編第九戰術戰鬥機作戰隊，賡續執行臺海防衛作戰。
        `
      }
    ],
    content: `民國106年空軍第四四三戰術戰鬥機聯隊更銜為空軍第一戰術戰鬥機聯隊。空軍第四四三戰術戰鬥機聯隊於民國34年9月15日成立於漢口，原稱空軍第一聯隊司令部，係中美空軍混合團結束後改組而成，直隸於航空委員會。民國35年秋，因空軍改行軍區制，第一聯隊番號遂即停用。民國41年12月1日我空軍在臺整編，部隊恢復第一聯隊番號，全銜稱「空軍第一戰鬥轟炸聯隊」駐防臺中，直隸空軍總司令部，換裝F-84G型機。民國43年8月14日成立「雷虎特技小組」。民國47年9月4日換裝F-86F型機。民國49年6月16日，聯隊奉令更銜為「空軍第一戰術戰鬥機聯隊」。民國59年12月16日增編第七十一空中支援管制隊及接收O-1G型機。民國63年12月17日換裝F-5E/F型機。民國65年8月16日更銜為「空軍第四四三戰術戰鬥機聯隊」。民國74年3月1日第七十一空中支援管制隊O-1G型機汰換，接收A-CH-1中興號攻擊機，番號更銜為「第七十一空中管制暨攻擊隊」。

民國76年5月1日增轄第七十二空中管制暨攻擊隊。民國78年5月12日總部考量聯隊戰演訓任務繁重，將「雷虎特技小組」編組移交空軍官校。民國80年8月1日第72隊裁編併入第七十一隊。民國85年1月1日依據本軍「精實案」兵力部署規劃，第七十一隊正式裁撤。民國86年3月執行「經國號」戰機換裝戰備整備任務，分別於民國87年2月19日與民國88年1月7日，完成第一、三中隊換裝及編成點驗任務。民國89年7月14日完成聯隊及九中隊換裝與編成點驗任務。民國91年11月30日將第一飛行大隊裁併改編，同年12月1日成立第一、三、九等三個作戰隊，以提升戰力。
    
民國95年依行政院國防自主及扶植國內航空工業發展為優先之指導，臺中漢翔公司執行「IDF-MLU(中壽期性能提升)之翔展計畫」。民國103年1月16日，前總統馬英九先生親臨主持「翔展專案」接裝典禮，除肯定順利完成IDF-C/D型機性能提升，有助臺海防衛與安全外，並期勉我空軍官兵未來能上下一心，賡續發揚「筧橋精神」。
    
聯隊現階段以爭取並保持臺海空中優勢為首要任務，並協力友軍遂行制海及反登陸作戰，俾維護國家領土主權安全。未來持續配合指、管、通、情系統，依令遂行各項戰備任務，並於重點戰備期間，加強空中監偵及實施空中戰鬥巡邏與警戒，使敵人不敢輕啟戰端。
    
近年來，第四四三聯隊戮力於主力戰機之戰備與演訓任務，包括參加「漢光演習」、「聯翔操演」、「三軍聯合實彈精準射擊」、「天龍演習」等大小演訓執行實兵操演任務10餘次，並投入演習兵力約1萬餘人次，有效提升空軍第一線作戰之能力。民國103年7月23日，馬基隊投入「澎湖復興馬公空難」救災任務，協助生還者傷患後送作業。民國104年4月1日美方2架F-18型戰機因機械因素，協助美籍戰機安全降落機場，並於維修期間提供適切與必要協助。民國106年2月23日由漢翔公司董事長廖榮鑫頒發聯隊「經國號戰機飛行時數滿千小時」證書，以表彰飛行員精湛的飛行技術及卓越的貢獻。
    
聯隊全體官兵在緬懷部隊光榮輝煌的歷史之餘，當念茲在茲，踵武前賢，恪遵各級長官諄諄教誨，置重點於落實部隊訓練工作、加強官兵福利服務、改善營舍工作環境，以提升部隊整體戰力。未來將持續規劃辦理營區參訪、人才招募、全民國防教育及軍民聯誼等活動，促進軍民友好關係，達成「部隊安全、軍人安家、軍眷安心」的「三安政策」之目標。
    `
  },
  {
    name: "空軍第二戰術戰鬥機聯隊",
    icon: "/8-1.png",
    children: [
      {
        name: "四十一戰鬥機作戰隊",
        icon: "/8-2.png",
        children: [],
        content: `民國29年12月25日本隊成立於四川成都，先後使用E-15、E-16、P-66、P-40、P-47等型機，45年換裝F-86F機17架，至61年7月5日換裝F-104機20架，於63年11月16日換F-100機18架，至72年7月1日換裝F-104機。73年阿里山8號換裝訓練完成。為因應時代之演變、科學之發展、作戰之需要，其編制、組織經奉令更改自72年9月種子教官訓練，奉總部簧約0269號令核定換裝F-104中隊編制。
        後為配合國防現代化，空軍主力戰機新紀元的開始，於86年5月23日換裝二代機M2000-5型戰機。該戰機具有高速度、短程起降、超高速巡航、較大任務半徑等多項特性。結合轉彎性能、酬載能力大、配有先進武器投射性能等優點之主力戰機，肩負空防任務及為臺海安全提供強大的屏護。93年11月01日為配合國軍精進案，成立第四十一戰術戰鬥機作戰隊。
        `
      },
      {
        name: "第四十二戰鬥機作戰隊",
        icon: "/8-3.png",
        children: [],
        content: `民國29年12月16日成立於四川成都隸屬第十一大隊。31年1月13日展開訓練擔任成都空防任務。31年1月完成戰備歷經抗戰等諸戰役均克敵致勝，戰績輝煌。35年8月奉令撤銷番號，至65年奉空軍總部恢復編制，隸屬五大隊。67年調防新竹，歸屬十一大隊。
        93年11月第十一大隊裁撤，第四十二中隊成為作戰隊，賡續秉持「忠勇軍風」及十一大隊光榮傳統，迎接新時代的來臨。
        `
      },
      {
        name: "第四十八戰鬥機作戰隊",
        icon: "/8-4.png",
        children: [],
        content: `民國29年12月25日以四十三中隊為前身成立於四川成都，正式開始接收E-15、E-16及霍克-75機。30年3月進駐邛崍，31年9月至印度接收P-66機，32年2月調返雙流擔任成都防空作戰任務，34年移駐西安，38年遷臺後進駐屏東基地，42年移駐新竹基地。48年7月5日我隊4架F-86機在馬祖上空遭遇13架匪米格17型機攻擊，奮勇接敵擊落匪機3架、重創1架，我機全部安返，戰果輝煌，聲振中外。
        53年9月1日奉國防部核定更改部隊番號為四十八戰術戰鬥機中隊，59年10月換裝F-100機，73年11月執行F-104機換裝，邁向倍音速里程。87年4月至11月接收M2000-5幻象戰機。93年11月起奉國防部令改編為換裝訓練隊，全力執行幻象戰機換裝及戰術專精訓練，厚植堅實戰力。
        `
      }
    ],
    content: `民國106年空軍第四九九聯隊更銜為空軍第二戰術戰鬥機聯隊。空軍第四九九聯隊之前身為空軍作戰情報指揮所，成立於民國39年1月16日，直隸空軍總司令部，統一指揮新竹防空區有關情報處理及海空作戰等諸般事宜。民國42年1月8日奉令成立空軍第二聯隊，民國65年1月1日更銜為「空軍第五一六戰術戰鬥機聯隊」，同年8月16日再變更銜為「空軍第四九九戰術戰鬥機聯隊」迄今。目前聯隊下轄第四十一、四十二作戰隊、第四十八換訓隊、第二修護補給大隊、第二基地勤務大隊、憲兵第二中隊，另配列通航資、天中及防砲等單位。聯隊曾使用P-4Y巡邏式轟炸機、B-24解放式轟炸機、B-25輕型轟炸機、C-46運輸機、C-123K型機、F-47雷霆機、F-86D/F軍刀機、F-104星式戰鬥機，擔負運補、空投、防空攔截、海空偵巡、掩護等任務。民國82年奉令換裝幻象戰機，民國85年起，相繼派遣飛行及技勤修護種子教官總計近190人次，前往法國接受換裝訓練，並於民國86年5月完訓返國，擔任換訓種子教官，同年5月23日，接收第1架幻象戰機。其後，幻象戰機中隊陸續編成，迄民國90年5月10日幻象戰機正式成軍擔負戰備任務，並由前總統陳水扁先生親臨編成典禮致詞，除對參與換裝訓練的官兵表達欽佩與嘉勉外，特別訓勉我全體官兵，要發揮先進武器的最大效能，有效掌握臺海制空權。

    聯隊自成立以來戰功顯赫，民國43年5月22日11大隊F-47型機2架至一江山、鯁門一帶執行偵察敵情任務，於大陳西南上空遭中共6架米格15型機圍攻，空戰中擊落敵機1架。同年11月23日參加烏坵戰役，共擊沉中共各型船艦15艘，阻絕中共進犯，為烏坵解圍。民國44年1月18日至22日支援一江山作戰，計出勤各型機156架次，共擊沉中共船艦17艘。同年2月6日至13日掩護大陳及南麂島撤退，擊沉中共各型船艦27艘。民國47年9月24日，我F-86F型機18架，由大隊長冷培澍上校率隊擔任掩護大陸偵照任務，於溫州灣上空附近遭遇中共米格機40餘架之攻擊，一舉擊落中共米格型機9架，創下「九二四空戰」的光榮戰史，亦首創以響尾蛇飛彈擊落米格機之紀錄。
    
    聯隊自換裝成軍擔負戰備以來，除達成交付之例行性作戰任務以外，亦曾多次擔任總統專機掩護、精準武器實彈射擊及「民雄戰備道」戰機起降等歷史性重大任務。近年，更不遺餘力深化「全民國防」教育，平時除邀請各校學子前來參訪，也於民國99年、101年、104年相繼辦理「國防知性之旅-營區開放」活動，讓國人近距離瞭解、見證新竹聯隊幻象戰機是個不斷「迎接挑戰、追求卓越」的戰鬥機聯隊，未來仍將秉持以往「從嚴從難、勤訓苦練」之精神，除精研戰術戰法外，另藉由各種與國外交流訓練時機，汲取他國實戰經驗，以研擬克敵之道，為空軍整體戰力提升開創優質契機。
    `
  },
  {
    name: "空軍第三戰術戰鬥機聯隊",
    icon: "/9-1.png",
    children: [
      {
        name: "第七戰鬥機作戰隊",
        icon: "/9-2.png",
        children: [],
        content: `前身為空軍第七戰術戰鬥機中隊，民國24年10月1日在南昌成立，原番號為空軍第七隊，直隸航空委員會。25年10月改隸空軍第三大隊，28年8月1日改用番號空軍第七中隊。26年至34年對日抗戰期間，曾駐防句容、漢口、樊城、孝感、衡陽、柳州、成都、重慶、桂林及安康等地，並曾至印度執行換裝任務。35年至38年戡亂任務期間曾駐防徐州、蚌阜、南京及舟山等地；39年臺海防衛任務期間駐防海南島、龍潭及屏東等地，至48年移駐臺中清泉崗基地。
        歷年所配之戰機有賚達二七式、霍克三型、E-15、E-16、P-66、P-40及P-51戰機，且於39年更名為戰鬥機中隊，45年換裝F-86型軍刀式戰機，奉命改編為噴射戰鬥轟炸機中隊，51年11月1日正式換裝F-104超音速星式戰鬥機，擔任全天候戰備任務。
        為因應後冷戰時期局勢及杜絕中共以武力犯臺野心，並配合國防武力更新自主的自主需要，籌備二代戰機換裝計劃，並交付本隊執行由國人自製之經國號戰機換裝，以取代機齡老舊之F-104戰機。81年1月1日成立經國號換裝整備小組，81年7月1日接收第一架先導生產型經國號戰機，同時成立經國號戰機種子教官中隊，完成本大隊三個中隊之IDF經國號戰機換裝任務；並於93年11月1日改編為空軍第七戰術戰鬥機作戰隊。94年元旦因賦予任務調整，因而將經國號戰機換訓任務移由其他部隊執行。
        `
      },
      {
        name: "第二十八戰鬥機作戰隊",
        icon: "/9-3.png",
        children: [],
        content: `前身為空軍第廿八戰術戰鬥機中隊，民國25年8月10日成立，隸屬空軍第五大隊。前身原為空軍廣州第六中隊，因兩廣事件，由隊長馬庭槐率領飛往南昌，續轉杭州。民國27年改隸空軍第三大隊於江蘇句容。珍珠港事件後，奉命成立中美混合團，與美空軍並肩作戰，直至抗戰勝利。為擔任空防要務，本隊先後駐防南昌、杭州、句容、孝感、紹關、太原、廣州、洛陽、漢口、衡陽、梁山、雙流、重慶、桂林、徐州、濟南、海口等地，民國39年來臺進駐屏東，於48年轉駐清泉崗基地迄今。本隊歷經抗戰、戡亂及臺海防衛等戰役，於抗戰中擊落日機47架，擊傷13架；擊毀地面飛機56架及車船人員不計其數。更於27年9月13日太原空戰中擊俘日少佐指揮官驅逐之王－「三寬輪」乙員。其後於戡亂及臺海八二三戰役中，執行密支、偵巡、掩護、夜巡等任務，均圓滿達成。
        本隊成立之初，原使用霍克Ι型戰鬥機，先後換裝E-15、P-40、P-51、P-47、F-86、F-104等戰機。49年換裝F-104型戰機，保障臺澎金馬之空防，並達成各項任務，深獲各級長官好評，曾四次榮膺國軍莒光連隊，84年換裝我國自製新一代戰機-IDF經國號戰機，並於93年11月1日改編為空軍第廿八戰術戰鬥機作戰隊迄今。
        `
      }
    ],
    content: `民國106年第四二七聯隊更銜為空軍第三戰術戰鬥機聯隊。空軍第四二七聯隊之前身為空軍第三大隊，於民國25年10月16日正式成立於江蘇句容，直隸於航空委員會，下轄第七、八及十七等三隊，使用霍克Ⅲ、波音281、霍克Ⅱ、費亞提等型機，民國26年原轄第十七隊與空軍第五大隊之第二十五隊對調隸屬，民國27年陸續使用I-15、I-15Ⅲ、I-16等型機，4月將第二十五隊撥歸美國顧問陳納德指揮訓練，同時第三十二隊改隸第三大隊，8月移防衡陽，接受空軍驅逐總隊之指揮，10月將第二十八隊改隸大隊，民國28年8月所屬各隊改用中隊番號，期間參加抗戰之南京、句容、揚州、京滬、樂昌、武漢、魯南、桂南等戰役，戰果豐碩。

    民國29年全大隊駐防成都太平寺，5月所屬第八中隊與航空委員會直轄第二十五中隊人員對調服務，民國31年6月三大隊改受空軍第三路司令指揮部指揮，並派員赴印度接收P-66新型機，11月又改由空軍第一路司令部指揮，民國31年遷駐四川，擔任保衛戰時陪都西南後方空防任務，次年2月遷回太平寺，3月復歸第三路司令部指揮。
    
    民國32年太平洋戰爭爆發，10月1日中美空軍混合團於印度卡拉齊成立，編入中美空軍混合團，改受該團指揮，並移駐桂林，使用P-40型機，轉戰廣州、常德、鄂西、鄂北、豫南及長江沿岸等各戰場，先後創下擊落日機140架之輝煌紀錄。民國34年對日抗戰勝利後，中美混合團裁撤，大隊改受空軍第一聯隊指揮，駐防徐州擔任綏靖任務；民國35年航空委員會改組，大隊改隸空軍總司令部，受空軍第四軍區司令部指揮，此時使用P-51型機，同時第三十二中隊裁撤。中共叛亂，大隊又先後參加蘇北、淮陰、邵伯、東台、濟南、徐州、臨城、魯南、魯西、魯中、豫東、豫北等剿匪諸戰役，直至民國38年隨政府轉進來臺，進駐屏東基地整軍備戰，民國41年換裝P-47螺旋槳戰鬥機。
    
    民國42年1月12日於屏東基地擴編為空軍第三聯隊，使用P-47螺旋槳戰鬥機及C-46運輸機，下轄第三大隊、第十一大隊、第二○三修護補給大隊、第二一三基地勤務大隊暨第三醫務隊，同年5月1日第二○三修護補給大隊、第二一三基地勤務大隊暨第三醫務隊，奉令更銜為第三修護補給大隊、第三基地勤務大隊暨第三醫務中隊，7月1日第十一大隊改隸第二聯隊，8月1日第四聯隊第十大隊改隸聯隊。
    
    民國44年12月換裝F-86F軍刀式噴射戰鬥機，民國47年「八二三」臺海之戰，擊落中共米格17型機2架，民國48年11月16日移駐臺中清泉崗基地，第十空運大隊改隸第六聯隊。民國49年5月26日接收首架F-104B型機，實施「阿里山」計畫，全面換裝F-104星式戰鬥機，並陸續接收：F-104A、F-104B、F-104G、TF-104G、F-104D、F-104J、F-104DJ等型機，執行24小時全天候作戰任務，民國54年榮獲加州洛廠頒發飛安永久獎之世界紀錄；民國56年「一一三」臺海空戰，擊落中共米格19型機2架，創下F-104型戰機首次擊落米格機的世界紀錄。
    
    中美兩國為加強雙方軍事合作，共同保衛臺、澎、金、馬之安全，於民國52年開始，美軍陸續進駐基地。民國61年11月8日越戰期間，美空軍第十八聯隊更以三個F-4C幽靈中隊進駐，直到民國68年中美斷交，美軍撤離。期間中美空軍不論是飛行、修護、通航各方面均奠定了中美聯合作戰深厚的基礎。
    
    民國65年1月1日更銜為「空軍第五四四戰術戰鬥機聯隊」，同年8月16日更銜為「空軍第四二七戰術戰鬥機聯隊」。民國66年6月10日聯隊增編第三十五作戰中隊，使用T-33A型攻擊機，擔負夜間炸射任務。民國72年8月1日，第三十五作戰中隊更銜為第三十五作戰隊，民國78年9月換裝AT-3雷鳴式戰機，因任務需要第三十五隊於民國81年移編新竹第二聯隊。
    
    民國80年元月聯隊榮膺空軍第一個換裝新一代戰機部隊，執行「合歡山」計畫，於民國81年接收首架生產型經國號戰機，第七中隊成立換裝小組執行換裝訓練，民國83年12月28日成立經國號戰機第一個作戰中隊「第八中隊」，民國84年11月22日成立第二個作戰中隊「第二十八中隊」，並於民國86年4月15日完成全聯隊經國號戰機換裝任務。
    
    因應國軍「精進案」的實施，民國93年11月1日，第三大隊及第八中隊裁撤，原第七及二十八中隊改編成作戰隊。未來，聯隊將秉持「勤訓、精練、務實、踏實」之精神，以實際行動，戮力戰訓本務，發揚筧橋精神，達成上級交付之任務。
    `
  },
  {
    name: "空軍第四戰術戰鬥機聯隊",
    icon: "/10-1.png",
    children: [
      {
        name: "空軍救護隊",
        icon: "/10-2.png",
        children: [],
        content: `民國39年6月1日，空軍第十大隊專機組成立直升機分隊，為本部救護隊之肇始。41年12月1日，有鑑於任務需要，將專機組直升機分隊擴編為空軍救護隊，43年7月1日再改編為空軍救護中隊，隸屬空軍第四聯隊，嗣後為配合軍事需要，加強海陸搜救，擴展救護任務，遂於46年2月1日奉令改編隸屬空軍第四聯隊，72年8月1日奉令更改番號為空軍救護隊，擔任海陸搜救及救災任務。救護隊成立之初使用C-47機及H-5、13直升機擔任搜救任務，44年換裝PBY-5A兩用機及H-19直升機，46年換裝HU-16（原稱為SA-16）水陸兩用機，以取代PBY-5A機，59年換裝HH-1H直升機取代H-19直升機，75年6月12日換裝S-70C-1A直升機，87年5月6日接收S-70C-6直升機，100年12月15日接收EC-225搜救直升機，101年7月10日舉行EC-225直升機成軍典禮（翔鷗操演），該型機正式擔負緊急救援任務。
        救護隊長期執行山難、海難、空難之搜救運傷及重大災害救援等工作，曾執行88年九二一震災、90年7月30日桃芝颱風救災、91年7月9日元勝二號大陸漁工船失火搜救，98年8月8日八八水災、99年凡那比、梅姬、101年6月10日豪雨緊急救援、撤離災民及運補，103年10月10日海洋研究五號船沉沒搜救等任務，救護隊全體空、地勤人員無不致力落實各項戰訓、飛安、修護等工作，除勤訓精練積極提升救援戰力外，並秉持「聞聲救苦、人命第一」救難理念，戮力維護全國人民生命財產安全與永續福祉，深獲國際友人、社會各界及全國民眾諸多好評。
        除了各作戰隊與救護隊，本聯隊下轄各支援單位，第四修護補給大隊，負責裝備保養維護及器材油彈補給；第四基地勤務大隊，負責聯隊官兵伙食、交通運補及基地設施改善，憲兵第四中隊負責營區警衛勤務暨安全維護；另配列通航資中隊、天氣中心、防砲部隊等單位，各部隊皆全力支援聯隊各項作戰與訓練整備工作，使聯隊整體戰力得以發揮。
        本聯隊具有非常優良的光榮傳統，是一支戰術訓練精良的空軍作戰勁旅，擔負臺海領空第一線空防警戒重任，全體官兵投入戰備均不敢稍有鬆懈，平時更由海鷗部隊，肩負全國救災任務，是一支結合救災與作戰於一體的空軍勁旅。尤深感國家培植之深，同胞托付之重，自成軍以來，全體官兵不分空、地勤，不論作戰、修護、後勤，無不精誠團結、淬礪奮發、矢勤矢勇、枕戈待旦、秉無堅不摧之信心與必勝必成的信念，繼續發揚「志航」傳統精神，永續經營，以達成國軍使命。
        `
      },
      {
        name: "第二十一戰鬥機作戰隊",
        icon: "/10-3.png",
        children: [],
        content: `本隊「正氣中隊」於民國25年10月16日成立於杭州筧橋。成立初期使用霍克Ⅲ及I-15型機，37年換裝P-51型戰鬥機，43年換裝F-84G型戰鬥機，進入噴射機時代。48年換裝F-100型超級軍刀機，即進入超音速歷程。民國67年完成F-5E型戰鬥機之換裝。86年10月4日完成E-16鳳凰戰機之編成。93年11月1日，因應國軍「精進案」政策推展，併編成立21作戰隊，賡續F-16戰機換裝訓練任務，為建軍備戰紮實根基，培養新一代國軍戰力。`
      },
      {
        name: "第二十二戰鬥機作戰隊",
        icon: "/10-4.png",
        children: [],
        content: `成立於民國25年10月16日，取乘長風破巨浪之氣魄，名曰：「長風」。抗戰期間使用霍克Ⅲ及E-15機征戰全國，擊落敵機27架，建立輝煌戰果。37年換裝P-51野馬式戰鬥機；43年換裝F-84雷霆式噴射機，執行大陸沿海偵巡作戰任務，多次擊毀匪船艦及支援外島作戰，戰績卓著；更於45年7月21日擊落米格17機兩架、擊傷兩架，突破當時臺海空優僵局，開啟八二三砲戰勝利之門。49年完成F-100超級軍刀機換裝，進入超音速戰機時代。68年3月換裝F-5E機老虎Ⅱ型機並完成戰備，護衛臺海領空安全，執行作戰、演習及訓練任務。87年7月完成F-16戰隼型戰鬥機換裝及戰備，臺海空中情勢亦邁向全新紀元。
        本隊隊徽藍色代表藍天，黃色代表大地；神鵰持飛彈、炸彈代表戰鷹飛翔於天際，我武鷹揚，閃電為廿二隊番號，並代表風馳如電之意。全體官士兵未來將努力延續本隊光榮傳統，以締造更光輝之榮譽。
        `
      },
      {
        name: "第二十三戰鬥機作戰隊",
        icon: "/10-5.png",
        children: [],
        content: `民國25年10月16日成立於杭州筧橋，初期使用霍克Ⅲ及E-15型機歷經剿匪抗戰，轉戰各地，將士用命，屢建奇功。31年換裝P-40型戰鬥機，於梁山之役創輝煌戰果。37年換裝P-51型戰鬥機。41年換裝AF-6戰鬥機，駐金門近海巡邏攔截被匪劫之菲國民航C-47型機，虜獲匪徒，著有戰功。43年換裝F-84型戰鬥機，自此邁進噴射機時代。49年換裝F-100型戰鬥機，戰力愈益增強。68年4月奉令成立「自強中隊」接受全國同胞自強愛國獻機之F-5E型中正號戰鬥機18架，完成各項戰備訓練。87年10月完成F-16戰鬥機之換裝及戰備整備任務，本自強不息，精誠團結，大畏精神，執行作戰任務，確保臺海空優，再造光輝之榮譽。93年11月1日奉令改制為作戰隊。`
      }
    ],
    content: `民國106年空軍第四五五聯隊更銜為空軍第四戰術戰鬥機聯隊。空軍第四五五聯隊駐防於嘉義基地，處嘉南平原北端、北回歸線交會處，是我國第一個部署Ｆ-16型機的空軍基地，堪稱為「臺灣戰隼的故鄉」，亦為我國保衛臺灣西部領空不可或缺的軍事駐地。聯隊成立於民國42年元月14日，由原有空軍第四作戰大隊、第十空運大隊、第二○四修護補給大隊、第二一四基地勤務大隊及第四醫務隊編成，命名為空軍第四聯隊。民國43年7月1日，第十大隊之直升機分隊擴編為救護中隊，直隸於聯隊。民國65年8月16日更銜為「空軍第四五五戰術戰鬥機聯隊」。

    民國72年8月1日救護中隊奉令更銜為「空軍救護隊」。民國93年11月1日，第四大隊、第十四隊併編成立二十一、二十二、二十三作戰隊，目前聯隊下轄第二十一、二十二、二十三作戰隊、救護隊、第四修護補給大隊、第四基地勤務大隊及憲兵第四中隊，另配列通航資、天中及防砲等單位。
    
    聯隊自成立以來，先後有周志開、蔡名永、高又新、歐陽漪棻等4為先進，因戰績卓著而獲頒青天白日勳章，由於部隊長治軍嚴謹，全體官兵辛勤努力，成效優異，故亦有「皇家空軍」雅號。其中，尤以首任第四大隊大隊長高志航於民國26年8月14日，率機起飛迎擊日軍鹿屋航空隊九六式重轟炸機，首開紀錄，擊落1架。其他隊員亦在空中英勇奮戰。此役為中國空軍八年抗戰打開勝利之門。後世為此役創亞洲空戰全勝之首局以及高烈士奮不顧身迎擊，一心忠膽愛國之精神，定8月14日是為空軍節。
    
    聯隊成立後，志航精神歷久彌新，歷經P-47、P-51、AT-6、T-33、F-84G、F-100A/F、F-5E/F及F-16A/B等各型機種換裝，更奉令成為我國第一個換裝Ｆ-16戰機之部隊，全體官兵即戮力以赴、積極整備，於民國90年12月18日正式成軍完成戰備任務，建立堅強的嚇阻戰力，並全力投入捍衛臺海領空之重責大任。三個作戰隊平時戮力於備戰演練，配合F-16型戰機絕佳性能，在歷次戰演訓中均能圓滿完成任務。
    
    20年來，嘉義基地從派員至美國受訓、接機抵臺，這一段從無到有，是許多前輩們打拼而來的基石，心中支持的力量是那顆熱愛中華民國的心，未來也會在前輩們辛苦建立的基礎上繼續為中華民國付出，守護我們愛的這塊土地。
    
    除作戰隊是中華民國西部領空鐵翼外，第四五五聯隊更有一對秉持「聞聲救苦、人命第一」的慈愛羽翼，人稱「慈航天使」的空軍救護隊，擔任海陸搜救及救災任務。民國100年12月15日接收EC-225搜救直升機，民國101年7月10日，由前總統馬英九先生主持「EC-225直升機成軍典禮」，該型機正式擔負緊急救援任務。救護隊長期執行山難、海難、空難之搜救運傷及重大災害救援等工作，曾執行民國88年九二一震災、民國90年7月30日桃芝颱風救災、民國91年7月9日元勝二號大陸漁工船失火搜救，民國98年8月8日八八水災、民國99年凡那比、梅姬、民國101年6月10日豪雨緊急救援、撤離災民及運補，民國103年10月10日海洋研究五號船沉沒搜救等任務。
    
    第四五五聯隊具有非常優良的光榮傳統，是一支戰術訓練精良的空軍作戰勁旅，擔負臺海領空第一線空防警戒重任，全體官兵投入戰備均不敢稍有鬆懈，平時更由海鷗部隊，肩負救災任務，是一支結合救災與作戰於一體的空軍勁旅。聯隊深感國家培植之深，同胞託付之重，自成軍以來，全體官兵不分空、地勤，不論作戰、修護、後勤，無不精誠團結、淬礪奮發、矢勤矢勇、枕戈待旦，秉無堅不摧之信心與必勝必成的信念，繼續發揚「志航」傳統精神，永續經營，以達成國軍使命。
    `
  },
  {
    name: "空軍第五戰術混合聯隊",
    icon: "/11-1.png",
    children: [
      {
        name: "第十七戰鬥機作戰隊",
        icon: "/11-2.png",
        children: [],
        content: `原為空軍第十七中隊，於民國25年8月10日成立於杭州筧橋隸屬三大隊，同年9月改屬五大隊。28年駐守蘭州受俄式E-15機換裝訓練，未完訓即遭敵機來襲，我機以9架迎戰敵機27架，並以6比０戰績再創光榮紀錄。37年冬自南京移駐臺灣桃園基地。43年首先換裝F-86軍刀型戰機。62年3月18日奉總部核定改隸第十一大隊同時換裝F-100型超級軍刀機並移駐嘉義基地實施訓練。於同年6月8日完成，63年12月1日奉令歸編第五大隊並換裝T-38A型機同時進駐臺南基地實施換裝訓練，於64年5月底完成訓練，64年6月1日奉令實施F-5A/B型機換裝，繼而進入F-5E/F型機訓練。65年1月完成移交工作，並於1月4日完成F-5E/F型機換裝。87年7月1日奉令自桃園移駐花蓮基地，並於同年進駐嘉義基地實施F-16型機換訓。於88年3月18日完成編成點驗，成為本聯隊第一個F-16中隊。
        本中隊原隸屬於四O一聯隊下轄第五大隊之第十七戰術戰鬥機中隊，依總部令於93年1月成立F-16戰精班任務，並於11月1日起改編為第十七戰術作戰隊，成為作戰隊編制後，接替七三七聯隊下轄第七大隊第四十六戰術戰鬥機中隊之假想敵任務。
        `
      },
      {
        name: "第二十六戰鬥機作戰隊",
        icon: "/11-3.png",
        children: [],
        content: `本作戰隊原為空軍教導第三隊，民國25年10月16日奉令改編為空軍第二十六中隊，於南昌成立，隸屬第九大隊，同年12月，奉調洛陽，26年1月後奉令改編為驅逐第二十六中隊，直屬航空委員會，2月8日調駐蘭州接PF-6機，同年7月中旬調駐漢口參加抗戰歷經「八一二」、「八一八」、「八二O」等空戰諸役。
        27年7月於衡陽奉令改隸空軍第五大隊，28年1月移防成都。34年8月抗戰勝利奉令進駐南京，37年冬調駐臺灣桃園基地整訓。43年7月12日至45年1月1日，本作戰隊先後完成T-33、F-86機換裝，47年「八一四」首開紀錄於平潭東北擊落米格十七型機3架，「九八」於澄海東北擊落米格十七型機5架、傷1架，「九二O」於圍頭附近擊傷米格十七型機1架，「九二四」於鎮海擊落米格十七型機1架，戰果豐碩舉世矚目。59年3月1日起奉令換裝F-5A/B型機，66年2月21日奉令換裝F-5E型機，並於7月開始擔任各種戰演訓任務。87年11月1日起奉令換裝F-16型戰機，89年6月12日奉令擔負魚叉飛彈換訓任務，於89年10月1日起擔負戰備，90年4月3日創下首次以F-16型戰機發射魚叉飛彈之壯舉。93年11月1日奉令成立戰術戰鬥機作戰隊，擔負制海作戰任務。
        `
      },
      {
        name: "第二十七戰鬥機作戰隊",
        icon: "/11-4.png",
        children: [],
        content: `民國25年10月16日在杭州成立，隸屬空軍第九大隊，隨後又轉駐南昌。26年6月調駐蚌埠整訓，同年9月移駐漢口。27年3月，奉令進駐梁山，實施霍克III、E-15及E-16式飛機訓練。27年5月，第九大隊奉令撤銷，改編為驅逐第廿七中隊直屬航空委員會。28年8月奉令改隸五大隊，進駐成都。33年4月，奉令赴印度接收P-51型戰鬥機，返國後進駐芷江並參與長衡保衛戰。34年8月，抗戰勝利奉令還都，進駐南京，35年秋，奉令調駐青島，擔任剿匪任務。37年冬，剿匪戰局逆轉，調駐臺灣桃園，於39年換裝P-47型機，執行臺海作戰及巡邏任務。43年7月接收F-86機，從此進入噴射機時代，並以F-86型機在臺海戰役中擊落米格機多架。57年4月1日奉令換裝（赴臺南基地接收）F-5A/B型雙發動機戰轟機。62年1月接收T-38型機，擔任各項演訓任務及新進人員訓練，績效卓著。64年5月換裝F-5A/B型機，執行偵巡掩護及警戒等戰演任務。66年9月奉令接收F-5E/F型機，擔負各項戰演訓任務。
        87年7月1日，因應新一代機兵力整建，及遵奉國軍兵力精實案之指導，自桃園基地移駐花蓮基地。87年12月奉令至嘉義基地換裝F-16型戰機，自此中隊進入新一代戰機時代，於88年10月完訓返防花蓮基地，賡續執行各項戰演訓任務。93年11月1日，依國防部國軍兵力精進案組織再造，戰術戰鬥機中隊改制為戰術戰鬥機作戰隊。
        `
      },
      {
        name: "第十二戰術偵察機隊",
        icon: "/11-5.png",
        children: [],
        content: `民國24年3月1日成立於江西南昌，名為「空軍第八隊」，5月更改番號為「空軍第十二隊」，直隸航空委員會。32年冬，因裝備器材無法獲得補充，而撤銷編制，保留番號；34年3月為應抗戰所需，奉命復制於四川遂寧，使用可塞、許來克、復興、白郎卡、SB、SBIII、北美、RP-51等型偵察機。抗戰勝利後，移駐南京，並分期完成全國各機場之空照任務。民國37年起大陸局勢逆轉，奉令移駐臺灣桃園基地，41年改隸空軍第五聯隊。43年7月1日應反攻大陸作戰之需，成立空軍第六戰術偵察大隊，本隊改隸旗下，並更名為「空軍第十二戰術偵察中隊」，使用RP-51、RB-25、RT-33等三型偵察機。44年換裝RF-86，即服行任務，45年初賡續換裝RF-84，偵照任務地區遍及大陸東南各省，47年9月，RF-86型機停止服行任務，53年2月RF-84型機移交美軍並換裝RF-104。62年2月1日第六戰術偵察大隊裁撤，本隊改隸第四O一戰術混合聯隊，72年8月1日奉命更名為「空軍第十二戰術偵察機隊」，同時RF-104型機經歷年戰訓耗損，亦於「阿里山八號」專案獲得補充，並執行「始安計畫」，換裝新型長焦距傾斜相機，73年10月1日增編「中興分隊」，使用國造RCH-1中興號偵察機，執行近沿海巡邏任務。
        79年12月15日中興分隊奉令移駐臺南，81年9月30日RF-104G移駐新竹，本隊於83年3月16日奉令配屬第四九九聯隊，84年11月28日中興分隊返防桃園，86年7月1日依「虎瞰計畫」換裝RF-5E，民國87年5月22日F-104G機汰除，人員返防桃園；前後自79年12月起，本隊配合各機種戰、演、訓任務而分散於三處基地（四O一、四九九、四四三聯隊），歷經8年終於返防桃園基地。87年7月1日，RF-5E型機及部隊菁英移轉成立「第四中隊」，部隊奉令隨四O一聯隊移編花蓮基地，因營舍關係仍暫駐桃園基地賡續執行「鳳眼專案」換裝任務，本隊復分處於三處基地（四O一、四五五聯隊及桃指部）；旋於88年11月、12月先後於嘉義基地接收6架F-16型新機，並於89年元月飛返花蓮。94年7月1日第四中隊因桃園基地指揮部裁撤，併編至本隊。
        `
      }
    ],
    content:`民國106年空軍第四○一戰術混合聯隊更銜為空軍第五戰術混合聯隊。空軍第四○一戰術混合聯隊前身為空軍第五戰術戰鬥機大隊，民國25年奉令成立於杭州筧橋，直屬航空委員會。民國28年改隸空軍第三路司令部，民國32年編屬中美混合團，駐防湖南芷江，民國34年抗戰勝利還都南京，中美混合團解散，改隸空軍第一聯隊司令部，進駐南京大校場。民國37年底奉命遷臺，進駐桃園基地。民國42年偵察部隊改隸，擴編為空軍第五戰術戰鬥機聯隊。民國49年更銜為「空軍第五混合聯隊」。民國65年更銜為「空軍第四○一戰術混合聯隊」迄今。

    在抗日戰爭期間，轉戰於京滬、粵北、豫、晉、蘇皖、西南北蘭州等戰區，計抗日8年期間，共擊落日機120餘架，並擊傷23架，其中以奇襲岳陽白螺磯機場一役，一舉毀傷日機多達兩百餘架，戰果最為輝煌。
    
    政府遷臺後，聯隊所屬第五大隊於民國44年10月15日臺海空戰中，首創擊落中共米格15型機1架。民國45年7月21日復於馬祖北部空域擊落中共米格機2架，爾後相繼於民國47年8月14日至10月10日臺海戰役期間，共擊落中共米格17型機達21架；聯隊於民國48年8月榮獲先總統 蔣公親授榮譽旗乙面；另所屬第十二戰術偵察機隊亦在歷次戰役中，冒險犯難，深入中國大陸地區執行偵照，蒐集情資掌握中共各項動態，以支援各項軍事任務達成。
    
    民國87年7月日配合「精實案」組織架構調整，奉命自桃園基地遷至花蓮基地，賡續執行完成F-16型機各項換裝訓練工作，以擔負捍衛國土之作戰任務。同時照技隊因政策改隸聯隊，配合第十二戰術偵察機隊建構空軍整體「鳳眼」偵照戰力，迄今完成多項重要照相情資，未來更將持續精進各項照情技術，提供更即時、正確之重要情資，支援國軍整體防衛作戰。
    
    聯隊目前由第十七戰術戰鬥機作戰隊、第二十六戰術戰鬥機作戰隊、第二十七戰術戰鬥機作戰隊、第十二戰術偵察機隊、第五修護補給大隊、第五基地勤務大隊、照相技術隊及各配列屬單位所組成，主要任務遂行各類型空中作戰任務，內容涵蓋制空、制海、對地攻擊、偵照及假想敵等，平時戮力戰訓本務，完成戰備；戰時則全力爭取制空權，並與陸、海軍遂行聯合作戰，以達成保家衛國之神聖使命。聯隊亦負責赴美國路克基地受訓之前置戰精訓練，精進戰術、戰鬥技能，使我空軍健兒在執行各項飛行任務能夠圓滿達成，確保臺海優勢。
    
    聯隊為部署主力戰機的科技勁旅，除擔負起臺灣東部領空作戰任務，更擔當維護臺灣東部空域安全之重責大任。因此，在戰備整備上，聯隊將戮力精進訓練，精實官兵技術、技能及戰技，並推動風險管理制度，以減少危安事件發生。面對未來，聯隊全體官兵當念茲在茲，踵武前賢，奮鬥不懈，恪遵各級長官諄諄教誨，在裝備上不斷更新，戰備上勤訓精練，使部隊戰力日益精實壯大，期許在未來發揚光榮傳統，完成歷史使命。
    `
  },
  {
    name: "空軍第六混和聯隊",
    icon: "/12-1.png",
    children: [
      {
        name: "第二十電戰大隊",
        icon: "/12-2.png",
        children: [],
        content: `創建於民國26年11月，番號立為空軍特務大隊，成立於南京；36年1月1日空運部隊擴編，奉命改稱為空運第一大隊，同年4月1日增設專機管理組，接收C-47、C-46、C-54及B-25等型機；37年1月16日奉令更銜為空軍第十大隊，並移防臺灣嘉義；48年11月16日改隸 六聯隊，駐防屏東，下轄一O一中隊、一O二中隊、一O三中隊，編配C-46型運輸機；55年各中隊完成換裝C-119型運輸機，並汰換原C-46型運輸機；75年7月一O一運兵機隊接收C-130H型運輸機；86年12月C-119型機「3158號機」完成最後一趟飛航任務後正式除役；94年7月1日一O三中隊正式裁編。
        本大隊現有大隊長室、作戰科、模擬機室3個幕僚單位，3個下轄單位分別為一O一中隊、一O二中隊及空投分隊。大隊成立至今為時70餘年，現有C-130H型運輸機18架。大隊隊徽為一勇毅駱駝馱負行囊，行進於紅色砂土上、駱駝可說是沙漠之寶，對於過往沙漠之商旅行人來說，駱駝之馱負能力及在沙漠中堅忍的表現，是其他動物所望塵莫及；大隊以駱駝為隊徽主體，除表現出執行任務的能力，猶如駱駝行於沙漠，並象徵大隊任重道遠，面對革命前程忍辱負重之精神，駱駝行於紅色沙土上，象徵該大隊具革命浴血奮戰之精神，而駱駝後的城堡，則意指我國軍固守之反共堡壘固若金湯、堅強且屹立不搖。
          `
      },
      {
        name: "第十空運大隊",
        icon: "/12-3.png",
        children: [],
        content: `民國37年1月1日原空軍第二大隊改番號為第二十大隊，轄原第二、六、十一及三十中隊，配備C-46機駐地上海，並於年底移駐臺灣新竹基地。42年1月8日第二十大隊納入新成立的第二聯隊；同年7月16日奉命以16架C-46機載運傘兵至東山島進行空降突襲，47年金門八二三砲戰爆發，美軍於10月底緊急運移C-119G空中車廂運輸機，並在臺南基地成立「獨立空運中隊」展開換裝訓練。50年3至5月間，聯隊奉令執行「國雷演習」，計出動C-46機321架次及C-119G機35架次，遠航至泰國清邁、清萊、南他等地接運泰緬邊境國軍游擊隊來臺，本大隊依令執行任務亦締造了光輝戰果。82年2月1日本聯隊奉令成立天干小組，派員分赴美國及中科院受訓，並對天干機進行作戰測試，天干部隊正式成軍。民國83年派員赴美國接受E-2T機換訓，84年6月1日奉令成立空中預警電戰機隊，該年11月1日E-2T機於清泉崗基地接受校閱正式成軍，機隊下轄4架預警機及1架天干機。89年1月1日空中預警電戰機隊奉令改制成立空軍第二十電戰大隊，下轄作維科、電戰支援科、第二預警機中隊及第六電子反制機中隊，運作迄今完成各項驗測、戰、演、訓、偵搜、救災等任務，成果卓越。
        撫今追遠，感懷先賢先烈，繼往開來，在完成接收鷹眼2000與天蠍星裝備之際，更應精進本職，勤研戰術、戰法、開創歷史新頁，完成國家所負重責大任。
        `
      },
      {
        name: "反潛作戰大隊",
        icon: "/12-4.png",
        children: [],
        content: `基於臺灣海峽反潛作戰之重要，於民國55年10月1日本聯隊奉總部(55)哲賢字第533號令轉奉國防部(55)平進字第982號令核定，飭成立反潛艇中中直屬本聯隊，使用S2A型反潛機。首任隊長由第二十大隊副大隊長曹楚才出任。65年54月換裝S2E型機，同年8月聯隊更改番號為第 六運兵反潛混合聯隊。68年2月1日奉空軍總司令部(67)松授字第998號令核准「空軍第三十三反潛中隊」擴編為反潛機大隊，並將原「空軍第卅四混合中隊」納編，並改番號為「空軍第卅四反潛中隊」。該大隊於81年10月15日開始換裝S2T機，88年7月1日奉國防部(88)奧奉第0497號令反潛機大隊移編海軍航空指揮部飛行一大隊。95年6月29日奉海軍司令部泰編字第0950000634號令核定編成海軍一三三、一三四作戰隊，自95年7月1日起生效，原飛行一大隊於同日裁撤。
        102年4月3日依國防部國略軍編字第1020000453號令，海軍定翼機移編空軍第 六聯隊以7月1日生效；為提昇反潛戰力計採購12架P-3C機，第一架於102年9月25日返國，迄今已接收4架。
        `
      }
    ],
    content: `民國106年第四三九聯隊更銜為空軍第六混和聯隊。空軍第四三九聯隊係於民國43年2月1日在臺灣省臺中市水湳成立空軍第六作戰聯隊，直屬空軍總司令部。初期使用C-46型空運機，為輕型空運作戰聯隊，主要擔負運兵、空投、空降及近海偵巡等任務。民國47年11月依作戰需求於臺南增設C-119型空運機獨立中隊。民國48年11月移駐屏東基地。民國49年6月聯隊更銜為「空軍第六聯隊」。民國65年4月換裝S-2E型機併入聯隊北場，負責空中海域巡邏反潛等作戰任務，同年8月，聯隊再更銜為「第四三九運兵反潛混合聯隊」。民國68年2月，反潛艇中隊擴編為反潛機大隊。由於S-2E型機齡老舊，旋於民國81年10月15日換裝S-2T型機，藉以提升性能。民國75年7月，聯隊接收C-130型運輸機，並成立101運兵機隊，加入空投、運兵等作戰任務。

民國84年6月1日，國防部自美國接收E-2T型機及C-130HE型機，成立預警電戰機隊，同時聯隊更銜為「第四三九混合聯隊」。民國89年元月1日預警電戰機隊因任務需要擴編為第20電戰大隊，提升整體防空預警能力。
    
聯隊自成軍以來，經歷多次戰役及艱險任務，如民國47年「八二三」金門砲戰中，擔負金門空投補給任務，歷經作戰33晝夜，出動飛機計659架次，空投軍品179萬1,052公斤，開創了空軍大規模空投、空運作戰之先例。民國50年3月17日接運泰、寮邊境反共義士返臺，任務之艱鉅媲美柏林空運。民國88年國內發生「九二一」大地震，聯隊成立救災中心，運輸救災物資逾15萬0,343公斤，同時派遣E-2T型機，擔任24小時空中中繼通信及災區空域管制，協助直升機執行山區救災任務，成效卓著。此外，於民國91年5月協助實施空中人工增雨作業，有效紓解本島旱象。民國93年7月2日「敏督利」風災期間，E-2T型機再度擔任中繼通信任務，執行山區救災等任務，均能圓滿完成，受到國人最高肯定，充分發揮我運兵部隊冒險運補，不畏苦、不怕難之精神。
    
民國95年4月15日，E-2K型機正式成軍並執行「翔鷹操演」，由前總統陳水扁先生親臨主持，驗證戰備整備完整，充分展現實戰能力。民國95年5月27日印尼發生強震，派遣C-130H型機執行人道救援任務，飛航時間逾164小時，救援物資總重量共計13萬2,004磅。民國98年10月菲律賓因風災造成嚴重災損，C-130H型機執行「慈航一號」任務，載運救災物資總重量達1萬8,409公斤。民國99年1月16日海地發生大地震，C-130H型機再度擔負空運任務，圓滿達成任務，充分發揮我國「人溺己溺、雪中送炭」之精神與敦睦邦誼。民國99年9月19日「凡那比」颱風造成屏東地區嚴重淹水，再次派遣E-2K型機擔任空中搜救中繼任務，體現全體官兵秉持「軍民同心、苦民所苦」精神，積極投入災害救援工作。
    
民國100年11月17日配合國軍規劃實施「佳冬段戰備跑道」啟用演習，派遣E-2K型機執行起降演練，圓滿達成任務。
    
民國102年3月28日舉行「鷹眼三號接裝典禮」，完成E-2K型機全面換裝，強化我國空軍預警及指管能力。民國102年7月1日海軍定翼機正式移編第四三九聯隊，並於民國102年10月31日由前總統馬英九先生親臨主持P-3C型機接機典禮，正式加入聯隊戰力行列，可擔負全天候反潛、偵巡戰力，開創反潛機新紀元。
    
聯隊自民國初期成立迄今，使用機型計有C-46、C-119、C-123、S-2A、S-2E、S-2T、C-130H、C-130HE、E-2T、E-2K及P-3C等各型機，在此漫長歲月中，歷任聯隊長及全體官兵均秉持犧牲奉獻、奮鬥不懈之精神，一面積極換訓提升裝備效能，一面戮力執行作戰及救災任務，方能有今日之輝煌成果，現今聯隊能結合三軍部隊，提供空降、空投之任務外，亦能受命獨立執行電子作戰，制敵機先，克敵制勝，有效支持整體作戰目標之達成，確保空軍鷹揚無敵。未來更將著重於與各重要指管系統，有效整合戰場整體資源，以發揮三軍聯戰統合戰力，爭取臺海領空戰力優勢。
    `
  },
  {
    name: "空軍第七飛行訓練聯隊",
    icon: "/13-1.png",
    children: [
      {
        name: "第七戰術戰鬥機作戰隊",
        icon: "/13-2.png",
        children: [],
        content: `奉前總部(67)松授820號令於67年11月1日成立，賦予第七戰術戰鬥機大隊番號，隸屬737聯隊，核定編制下轄大隊長室、政戰室、作戰科、維護科等4個幕僚單位及3個飛行中隊；73年1月1日原46中隊改編為獨立隊形態，隸屬聯隊；85年12月1日增編模擬機訓練室；89年12月1日維護科裁徹，部分人力併入作戰科；94年7月1日原戰術中心第46中隊改隸本大隊，下轄44、45、46中隊；97年7月作戰科更名為作維科；102年4月1日原政戰室併入大隊長室。`
      }
    ],
    content: `民國106年空軍第七三七聯隊更銜為空軍第七飛行訓練聯隊。空軍第七三七聯隊之成立，係本「戰略持久」之指導原則，增強作戰縱深，強化持續戰力，於民國58年11月奉令籌劃，隔年2月開始施工，民國60年8月完成第1期路面、營舍及公共設施等工程，同時成立第12基勤大隊，並配合「神鷹計畫」第2期工程實施基地勤務之整備，以利進駐。

聯隊之前身為「空軍部隊訓練中心」，於民國61年4月1日奉令成立。民國67年1月16日更銜為「空軍臺東指揮部」，因應作戰需要，於民國67年11月1日擴編為「空軍第七三七戰術戰鬥機聯隊」，下轄政戰部、督察室、人事組、行政室、作戰組、後勤組、主計組等七個幕僚單位及直屬三個大隊，一個醫務中隊。
    
民國73年1月1日奉令，將七大隊下轄之第四十六中隊改編為「空軍第四十六戰術戰鬥機隊」隸屬聯隊，負責全軍炸射學術學科師資訓練，使學校教育與部隊訓練相結合，以培養空軍優秀幹部。
    
民國74年7月1日行政幕僚單位重新編組，將原有之行政室精簡納入人事組，聯隊編制核定為六個幕僚單位、三個直屬大隊、第四十六戰術戰鬥機隊及醫務中隊。
    
民國77年7月1日增編戰術中心，下轄第四十六中隊及空儀組(ACMI)，除繼續負責空軍官校畢業飛行學官之戰技訓練外，並負責全軍戰術戰鬥專精學、術科之師資訓練及各戰鬥部隊之戰術輪訓，同時依令執行臺灣東部海域空巡，並配合東部發展計畫，積極致力建軍備戰之整備。
    
民國87年7月1日，聯隊改隸屬「空軍教育訓練暨準則發展司令部」。民國93年11月1日，聯隊各「組」改編為「科」，「管理資訊組」改編為「通資電科」。
    
民國94年7月1日，聯隊改直隸「空軍總司令部」其中「戰術訓練中心」改隸屬「空軍教育訓練暨準則發展司令部」，第四十六中隊改隸屬第七大隊，另第七修護補給大隊新編成「修護支援隊」駐地第四○一聯隊，負責第四○一聯隊所屬RF-5型機修護支援任務。
    
民國61年「空軍部隊訓練中心」使用T-33、F-86型機種，於民國66年7月換裝使用F-5A、F-5B型機，民國70年1月換裝使用F-5E型戰機，同年11月換裝使用F-5F型戰機迄今。
    
目前負責官校戰鬥組結訓任務學員F-5E/F型戰機換裝與執行各項戰、演、訓任務為主，對新進人員戰備訓練，全軍炸射專精訓練，師資之培養深具貢獻。
    
民國100年7月憲兵第七中隊因應「精粹案」組織變革調整，憲兵司令部所屬各基地憲兵部隊移編空軍司令部，以強化空軍基地防衛作戰效能。
    
民國103年10月第七醫務中隊改編為「醫務所」，移編至醫療部隊，原單位下轄「醫療分隊」及「航醫分隊」同時裁撤併入「醫務所」。
    
聯隊為空軍培育戰鬥機飛行員的主要單位，事關空軍戰力之消長，為承續「精益求精、永續經營」之願景，未來仍將朝「飛行訓練、人員培訓標準化」、「訓練管制、任務提示資訊化」、「機務支援、機隊管理合理化」及「安全教育、風險管控普及化」等4大目標持續精進，培育空軍凌雲御風之優秀戰鬥機飛行員，以維護臺海領空之安全。
    `
  },
  {
    name: "空軍松山基地指揮部",
    icon: "/14-1.png",
    children: [
      {
        name: "座機隊",
        icon: "/14-2.png",
        children: [],
        content: `源於民國35年十二月一日，由空軍第十大隊座機組加以擴編，於南京成立「專機組」，民國三十七年大陸遽變，「專機組」奉命遷臺，民國四十四年改隸松山基地大隊，更名為「專機中隊」。
        成軍以來，全體官兵用心協力，榮辱與共 ，曾支援「八二三砲戰」運補作戰，並且擔負整軍備戰各項任務，夙夜匪懈，績效卓著，至72年以「專機隊」擴編比敘大隊，並先後換裝B-727、BH-1900、FK-50新進客機，以達「安全、舒適、速捷」之任務要求；另完成EBH-1900換裝，擔負全國軍助航設施查核任務。緬懷先哲建軍藍縷，期勉同志粹勵精進，薪火相傳。本隊成立為執行專機任務為主，並兼負查核、偵巡、空運、觀測等多項任務。成立迄今雖經組織變遷，然在歷任隊長及全體官兵共同努力下，均能秉持光榮傳統，全力以赴，展望未來，並以「今日我以專機隊為榮，明日專機隊以我為榮」相互勉勵，再創「專機隊」光榮史頁。
        `
      },
      {
        name: "專機隊",
        icon: "/14-3.png",
        children: [],
        content: `本隊之前身為委員長侍從室飛機管理室，成立於民國25年夏，後於31年秋至35年冬改編至空運隊，繼續擔任領袖之空中交通任務，36年12月1日於南京成立專機組隸屬空運第一大隊，政府播遷來臺後將DC-3「美齡」號及DC-4「中美」號等二機進駐嘉義，55年本組奉令改隸屬空軍松山基地指揮部迄今，期間曾使用C-54、C-118、B-720、B-727、FK50、B-737-400等型座機。因應國軍組織再造，94年1月1日改制為座機隊，比照獨立隊運作。現今本隊編配B-737-800型3701號及FK-50型5001號兩型座機共同擔任國家正、副元首之國內外視導、參訪之空中交通任務。
        本隊成立迄今已臻75年，歷經剿匪、抗戰、戡亂及政府遷臺之勵精圖治，座機歷史陪伴我國成長，其任務咸繫國祚。本隊有幸擔任總統、副總統國內、外空中交通任務，全體座機同仁均能深切體認所擔負任務的重要性，自持自勵，矢勤矢勇，使每一趟任務均能達到「安全、舒適、便捷」之目標，樹立本隊優質文化與光榮傳統，願我座機同仁得以惕勵傳承，繼續發揚光大，承襲座機使命，開創座機榮耀，共創座機未來，以成就本隊對國家之貢獻。
        `
      }
    ],
    content: `民國106年空軍第七三七聯隊更銜為空軍第七飛行訓練聯隊。空軍第七三七聯隊之成立，係本「戰略持久」之指導原則，增強作戰縱深，強化持續戰力，於民國58年11月奉令籌劃，隔年2月開始施工，民國60年8月完成第1期路面、營舍及公共設施等工程，同時成立第12基勤大隊，並配合「神鷹計畫」第2期工程實施基地勤務之整備，以利進駐。

聯隊之前身為「空軍部隊訓練中心」，於民國61年4月1日奉令成立。民國67年1月16日更銜為「空軍臺東指揮部」，因應作戰需要，於民國67年11月1日擴編為「空軍第七三七戰術戰鬥機聯隊」，下轄政戰部、督察室、人事組、行政室、作戰組、後勤組、主計組等七個幕僚單位及直屬三個大隊，一個醫務中隊。
    
民國73年1月1日奉令，將七大隊下轄之第四十六中隊改編為「空軍第四十六戰術戰鬥機隊」隸屬聯隊，負責全軍炸射學術學科師資訓練，使學校教育與部隊訓練相結合，以培養空軍優秀幹部。
    
民國74年7月1日行政幕僚單位重新編組，將原有之行政室精簡納入人事組，聯隊編制核定為六個幕僚單位、三個直屬大隊、第四十六戰術戰鬥機隊及醫務中隊。
    
民國77年7月1日增編戰術中心，下轄第四十六中隊及空儀組(ACMI)，除繼續負責空軍官校畢業飛行學官之戰技訓練外，並負責全軍戰術戰鬥專精學、術科之師資訓練及各戰鬥部隊之戰術輪訓，同時依令執行臺灣東部海域空巡，並配合東部發展計畫，積極致力建軍備戰之整備。
    
民國87年7月1日，聯隊改隸屬「空軍教育訓練暨準則發展司令部」。民國93年11月1日，聯隊各「組」改編為「科」，「管理資訊組」改編為「通資電科」。
    
民國94年7月1日，聯隊改直隸「空軍總司令部」其中「戰術訓練中心」改隸屬「空軍教育訓練暨準則發展司令部」，第四十六中隊改隸屬第七大隊，另第七修護補給大隊新編成「修護支援隊」駐地第四○一聯隊，負責第四○一聯隊所屬RF-5型機修護支援任務。
    
民國61年「空軍部隊訓練中心」使用T-33、F-86型機種，於民國66年7月換裝使用F-5A、F-5B型機，民國70年1月換裝使用F-5E型戰機，同年11月換裝使用F-5F型戰機迄今。
    
目前負責官校戰鬥組結訓任務學員F-5E/F型戰機換裝與執行各項戰、演、訓任務為主，對新進人員戰備訓練，全軍炸射專精訓練，師資之培養深具貢獻。
    
民國100年7月憲兵第七中隊因應「精粹案」組織變革調整，憲兵司令部所屬各基地憲兵部隊移編空軍司令部，以強化空軍基地防衛作戰效能。
    
民國103年10月第七醫務中隊改編為「醫務所」，移編至醫療部隊，原單位下轄「醫療分隊」及「航醫分隊」同時裁撤併入「醫務所」。
    
聯隊為空軍培育戰鬥機飛行員的主要單位，事關空軍戰力之消長，為承續「精益求精、永續經營」之願景，未來仍將朝「飛行訓練、人員培訓標準化」、「訓練管制、任務提示資訊化」、「機務支援、機隊管理合理化」及「安全教育、風險管控普及化」等4大目標持續精進，培育空軍凌雲御風之優秀戰鬥機飛行員，以維護臺海領空之安全。
    `
  }
];

export { unitData };
